.monitoring {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;

  @include media('mobile') {
    padding: 0 16px;
  }

  .form {
    width: 564px;
    margin-top: 64px;

    @include media('mobile') {
      width: 100%;
    }

    .field {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    .select-project {
      margin-top: 24px;
    }

    .button {
      margin-top: 48px;
    }
  }
}