.first-monitoring {

  @include media('mobile') {
    min-height: auto;
    height: calc(100% - 88px);
    overflow: auto;
    padding-top: 140px;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .button {
    width: 94px;
  }

  .field {

    &.error {
      .field-text {
        position: absolute;
        bottom: -15px;
      }
    }
  }

  .popup__close {
    @include media('mobile') {
      display: none;
    }
  }

  .t-headline-2 {
    @include media('mobile') {
      margin: 0;
    }
  }
}