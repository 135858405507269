.main-footer {
  background: $main1;

  &__inner {
    padding: 128px 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list {
    display: flex;
    align-items: center;

    @include media('mobile') {
      flex-direction: column;
    }
  }

  &__list-item {
    position: relative;
    margin-right: 18px;

    @include media('mobile') {
      margin-right: 0;
      margin-bottom: 24px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 2px;
      height: 2px;
      background: $main18;
      top: 50%;
      transform: translateY(-50%);
      right: -8px;

      @include media('mobile') {
        display: none;
      }
    }

    &:last-of-type {
      margin-right: 0;

      @include media('mobile') {
        margin-bottom: 0;
      }

      &:before {
        display: none;
      }
    }
  }

  &__copyright {
    color: $main8;
  }
}