.main-header {
  width: 100%;
  max-height: 80px;
  background: linear-gradient(180deg, #F8F8F8 0%, $main1 100%);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }

  &__button {
    margin: 0;
    padding: 16px 24px;
  }

  &__burger {
    display: none;

    @include media('mobile') {
      display: flex;
    }

    .icon {
      width: 48px;
      height: 48px;
    }
  }
}