.help-form {
  width: 564px;

  @include media('mobile') {
    width: 100%;
  }

  .t-headline-2 {
    text-align: center;

    @include media('mobile') {
      text-align: left;
    }
  }
  .t-headline-3 {
    margin-top: 64px;
  }
  .field {
    margin-top: 16px;
  }
}
.field {
  textarea {
    min-width: 200px;
    width: 100%;
    height: inherit;
    min-height: 110px;
    border: 1px solid #CBCBCF;
    padding: 16px 44px 0 16px;
    border-radius: 8px;
    color: #2A2B3A;
    outline: none;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: .001em;
    line-height: 16px;
  }
}
