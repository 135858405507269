.monitor {
  width: 368px;
  min-height: 242px;
  background: $white;
  border: 1px solid $main1;
  border-radius: 8px;
  padding: 24px;
  flex-shrink: 0;
  margin: 24px 24px 0 0;
  position: relative;

  @include media('mobile') {
    width: 100%;
    height: auto;
    margin: 14px 16px 0 0;
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;

    .t-headline-4 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dots {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 24px;
      right: 12px;
      z-index: 3;

      @include media('mobile') {
        top: 13px;
        right: 7px;
      }

      .icon {
        width: 8px;
      }
    }
  }

  &__description {
    margin-top: 4px;
    display: flex;

    .t-caption {
      display: inline-block;
      color: $main8;

      &:first-letter {
        text-transform: uppercase;
      }

      &:not(:last-child) {
        &::after {
          content: '·';
          display: inline-block;
          margin: 0 4px;
        }
      }
    }
  }

  &__info {
    display: flex;
    margin: 24px 0 16px;

    @include media('mobile') {
      margin: 16px 0;
    }

    & > div {
      width: 100px;
      color: $blueGray;

      &:not(:last-child) {
        margin-right: 8px;
      }

      .t-label-2 {
        color: $main8;
        margin-top: 4px;
      }
    }

    .green {
      color: $green;
    }

    .gold {
      color: $gold;
    }

    .red {
      color: $red;
    }
  }

  &__line {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .line__icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      svg {
        fill: $main8;
      }
    }

    .t-label-2 {
      margin: 0 8px;
      flex-grow: 1;
      color: $main8;
    }

    .line__value {
      flex-shrink: 0;
      padding: 2px 4px;
      border-radius: 4px;
    }

    .alerts_new {
      background-color: $red;
      color: $white;
      margin-left: 8px;
    }

    .green {
      background-color: rgba($green, 0.1);
      color: $green;
    }

    .red {
      background-color: rgba($red, 0.1);
      color: $red;
    }
  }

  &-dropdown {
    width: 200px;
    height: auto;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 24px;
    right: 56px;
    background-color: $white;
    box-shadow: 0 4px 20px rgba(42, 43, 58, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
    z-index: 4;

    div {
      position: relative;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease;

      > a {
        padding: 0 16px;
        text-align: left;
        align-items: center;
        display: flex;
      }

      &:hover {
        background-color: $main1;
      }
    }

    &.dropdown {
      padding: 8px 0;
      opacity: 1;
      visibility: visible;
    }
  }

  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__errors {
    z-index: 6;
    pointer-events: none;
    transition: .4s ease-in-out;
    opacity: 0;
    position: absolute;
    left: -1px;
    right: -1px;
    top: 95%;
    background: $white;
    padding: 24px;
    border: 1px solid $main1;
    border-radius: 0 0 8px 8px;

    .list-errors {
      padding-left: 14px;

      .item-error {
        position: relative;
        margin-bottom: 8px;

        &:before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          top: 50%;
          transform: translateY(-50%);
          left: -14px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &--status-error {
          &:before {
            background: $red;
          }
        }

        &--status-warning {
          &:before {
            background: $gold;
          }
        }

        &--status-ok {
          &:before {
            background: $green;
          }
        }
      }
    }
  }

  &:hover {
    border-radius: 8px 8px 0 0;

    .monitor__errors {
      opacity: 1;
      top: 100%;
    }
  }

  &--stopped {
    .monitor__info {

      .t-headline-4 {
        color: #9999A2;
      }
    }

    .monitor__sync {
      color: #FA8C16;
    }

    .monitor__line {
      .line__value {
        color: #9999A2;
        background: #E5E5E7;
      }
    }
  }

  &--paused {
    .monitor__info {

      .t-headline-4 {
        color: #9999A2;
      }
    }

    .monitor__sync {
      color: #F5222D;
    }

    .monitor__line {
      .line__value {
        color: #9999A2;
        background: #E5E5E7;
      }
    }
  }
}