.app--dark {
  background: $main19;

  .auth {
    background: $main18;
  }

  .app-container {
    background-color: $main19;

    .container--profile {
      background: transparent;
    }
  }

  .header {
    background-color: $main18;
    border-bottom: 1px solid $main17;

    @include media('mobile') {
      background-color: transparent;
    }

    .search {
      input {
        background: transparent;
        color: $white;
        border: none;

        &:focus, &:hover {
          border: none;
        }
      }

      .input-wrapper__icon {

        svg {
          fill: $white;
        }
      }
    }

    .notification-icon {
      .icon {
        fill: $white;
      }
    }

    .user {
      span {
        color: $white;
      }

      .icon {
        fill: $white;
      }
    }

    .user-dropdown {
      background: $main18;

      a {
        color: $white;

        &:hover {
          background: $main15;
        }
      }
    }
  }

  .sidebar {
    border-right: thin solid $main17;
  }

  .container {

    .inner {
      .top-line {
        color: $white;

        .back {
          .icon {
            fill: $white;
          }
        }

        .notification-setting {
          .icon {
            fill: $white;
          }
        }

        .monitor-options {

          .icon {
            fill: $white;
          }
        }
      }

      .project-dropdown {
        background: $main16;

        div {
          color: $white;

          &:hover {
            background: $main17;
          }
        }
      }
    }
  }

  .new-monitoring {

    .button_bc-indigo {
      background: $indigo1;

      &:hover {
        background: $indigo;
      }
    }
  }

  .notification {
    background-color: $main18;
    border-left: 1px solid $main17;

    .t-headline-2 {
      color: $white;
    }

    .notification__item {
      border-bottom: thin solid $main19;
    }

    .notification__header {
      .t-headline-4 {
        color: $white;
      }
    }

    .notification__help {
      .icon {
        fill: $white;
      }
    }

    .notification__body {
      > .t-subtitle-2 {
        color: $white;
      }
    }
  }

  .tabs {

    .tab {
      color: $white;

      span {
        color: $white;
      }

      &.active {
        background-color: $main15;
      }
    }
  }

  .alert_warning {
    .alert__icon {
      .icon {
        fill: $red1;
      }
    }
    .alert__text {
      color: $red1;
    }
  }

  .alert_good {
    background: $main20;
    .alert__icon {
      .icon {
        fill: $white;
      }
    }
    .alert__text {
      color: $white;
    }
  }

  .monitor {
    background: $main18;
    border-color: $main17;

    .monitor__header {
      .t-headline-4 {
        color: $white;
      }

      .dots {
        .icon {
          fill: $white;
        }
      }
    }

    .monitor__info {

      .red {
        color: $red1;
      }
    }

    &--paused, &--stopped {
      .monitor__line .line__value {
        background: $main16;
      }
    }
  }

  .monitor-dropdown, .project-dropdown {
    background: $main17;

    div {
      color: $white;

      &:hover {
        background: $main15;
      }
    }
  }

  .popup {
    background: rgba(26, 27, 39, 0.6);

    .popup__close {
      .icon {
        fill: $white;
      }
    }

    .popup-inner {
      background: $main18;

      .t-headline-3, .t-headline-4 {
        color: $white;
      }
    }
  }

  .button {
    &_bc {
      &-main18 {
        background-color: $indigo1;
        color: $white;

        &:hover {
          background-color: $indigo2;
        }

        &.disabled {
          background-color: #2B47B7;
        }

        svg {
          fill: $white;
        }
      }

      &-indigo {
        background-color: $indigo1;
        color: $white;

        &:hover {
          background-color: $indigo2;
        }

        &.disabled {
          background-color: #ABB8ED;
        }

        svg {
          fill: $white;
        }
      }

      &-red {
        background-color: $red1;
        color: $white;

        &:hover {
          background-color: $red;
        }

        &.disabled {
          background-color: $main4;
        }

        svg {
          fill: $white;
        }
      }

      &-main2 {
        background-color: $main15;
        color: $white;

        &:hover {
          background-color: $main14;
        }

        &.disabled {
          color: $main6;

          svg {
            fill: $main6;
          }
        }
      }

      &-white {
        background-color: $white;

        &:hover {
          background-color: $white;
        }

        &.disabled {
          color: $main6;

          svg {
            fill: $main6;
          }
        }
      }

      &-transparent {
        background-color: transparent;
        color: $white;

        &:hover {
          background-color: $main2;
          color: $main18;
        }

        &.disabled {
          color: $main6;

          svg {
            fill: $main6;
          }
        }
      }
    }
  }

  .field {
    .t-label-2 {
      color: $white;
    }

    textarea {
      border: 1px solid #CBCBCF;
      color: $white;
      background: transparent;

      &:focus {
        border: 1px solid $white;
      }
    }

    label {
      .required {
        color: $red1;
      }
    }
    &-text {
      color: $main9;
    }

    &.error {
      label {
        color: $red1;
      }
      .input-wrapper {
        input, textarea {
          border: 1px solid $red1;
          &:focus {
            border: 2px solid $red1;
          }
        }
      }
      .field-text {
        color: $red1;
      }

      .select {
        border-color: $red1;
      }
    }
  }

  .select-wrapper {
    .options {
      background: $main16;

      .option {
        color: $white;

        &:hover {
          background: $main17;
        }
      }
    }
    .select {
      border-right: 1px solid #5F5F6C;
      color: $white;
    }
    .icon-arrow {
      .icon {
        fill: $white;
      }
    }
  }

  .select-project {
    .button-add-project {
      background: #494A57;

      .icon {
        fill: $white;
      }
    }
  }

  .input-wrapper {
    input {
      border: 1px solid $main13;
      color: $white;
      background: transparent;

      &::placeholder {
        color: $main9;
      }

      &:hover {
        border: 1px solid $main9;
      }

      &:focus {
        border: 2px solid $white;
      }
    }

    &__email {
      input {
        border: none;

        &:hover {
          border: none;
        }

        &:focus {
          border: none;
        }
      }
    }

    &__icon {
      svg {
        fill: $main9;
      }
    }
    input:focus ~ .input-wrapper__icon {
      svg {
        fill: $white;
      }
    }
    input:not(:invalid) + .input-wrapper__icon {
      svg {
        fill: $white;
      }
    }
    &__icon-right {
      &.icon_clear {
        svg {
          fill: $white;
        }
      }
      &.icon_hidden {
        svg {
          fill: $main8;
        }
      }
    }
    input:focus ~ .input-wrapper__icon-right {
      &.icon_hidden {
        svg {
          fill: $white;
        }
      }
    }
  }

  .popup-share-project {
    .t-button {
      color: $white;
    }

    .t-body-2 {
      color: $white;

      &.no-invite {
        color: $main12;
      }
    }
  }

  .sign-in, .sign-up {
    .t-headline-2, .forgot-password, .t-button a, .form__agreement a {
      color: $white;
    }
  }

  .fspopup {
    background: $main18;

    .page-text {

      &__title {
        color: $white;
      }

      &__block {
        color: $white;
      }
    }

    &__close {

      .icon {
        fill: $white;
      }
    }

    &__back {
      .icon {
        fill: $white;
      }

      span {
        color: $white;
      }
    }
  }

  .auth-popup {

    .t-headline-2 {
      color: $white;
    }

    .popup__back {
      .icon {
        fill: $white;
      }

      span {
        color: $white;
      }
    }
  }

  .invite-project {
    &__bg {
      background: rgba(26, 27, 39, 0.6);
    }

    &__popup {
      background: $main18;
    }

    &__title {
      color: $white;
    }
  }

  .select-monitoring {

    .t-headline-2, .t-headline-3 {
      color: $white;
    }

    .other-monitoring {

      &__icon1 .icon, &__icon2 .icon {
        fill: $white;
      }

      &__info {
        .title {
          color: $white;
        }
      }
    }
  }

  .first-monitoring {
    background: $main18;

    .t-headline-2 {
      color: $white;
    }
  }

  .simple-monitoring {
    color: $white;

    .sequence {
      margin-top: 0;

      div {

        &.active {
          color: $white;

          p {
            background-color: $white;
            color: $main18;
          }
        }
      }
    }
  }

  .monitor-page {

    .line-information {
      background: $main18;
      border: 1px solid $main18;

      .line-information__item {
        color: $white;

        .icon {
          fill: $white;
        }
      }

      .line-information__col {
        color: $white;
      }
    }

    .monitor-sync {
      background-color: $main18;
      border: 1px solid $main18;

      &__title {
        color: $white;
      }
    }

    .monitor-blocks {

      .disabled-popup {
        background: rgba(73, 74, 87, .5);
      }

      & > div {
        background-color: $main18;
        border: 1px solid $main18;

        .t-headline-4 {
          color: $white;
        }
      }

      .overview, .hardware {
        background: $main18;
        border: 1px solid $main18;

        .t-headline-4 {
          color: $white;
        }

        .block-line {
          border: 1px solid #343544;

          > div {
            .block__value {

              &.red {
                color: $red1;
              }
            }
          }
        }

        .block__icon {

          .icon {
            fill: $white;
          }
        }
      }

      .uptime {
        .uptime-chart {
          .chart-day {
            &.red {
              border: 1px solid $red1;
            }
          }
        }
      }
    }
  }

  .profile {

    @include media('mobile') {
      background: $main19;
    }

    &__main-info {
     .main-info__name, .main-info__email {
       color: $white;
     }
    }

    .profile-back {

      .icon {
        fill: $white;
      }

      span {
        color: $white;
      }
    }
  }

  .profile-blocks {
    .t-headline-4, .t-input, .t-body-2, .t-inter {
      color: $white;
    }
  }

  .profile-account {

    .account-email, .account-password, .account-appearance {
      background: $main18;
      border-radius: 16px;
      border: 1px solid $main17;

      @include media('mobile') {
        padding: 16px;
      }
    }
  }

  .profile-tabs {

    .separators {
      background-color: $main16;
    }

    .tab {
      color: $white;

      &:hover {
        background: $main17;
      }

      .icon {
        fill: $white;
      }
    }

    .active .tab {
      background: $main18;
    }
  }

  .profile-monitorings, .profile-billing {
    .summary {
      background: $main18;
      border: 1px solid $main17;

      &__text, &__price {
        color: $white;
      }

      .summary-item {

        &__block {
          border: 1px solid $main17;
        }

        &__row {
          border-bottom: 1px solid $main17;

          &:not(:first-of-type) {
            color: $white;
          }
        }
      }
    }

    .payment-methods {
      .method-block {
        border: 1px solid $main17;
      }

      .checkbox {

        &__text {
          color: $white;
        }

        &__icon:after {
          background: $white;
        }
      }
    }
  }

  .profile-notifications {
    .notifications {
      .notification-block-header {
        .checkbox {
          .icon {
            fill: $white;
          }
        }
      }

      .notification-block {
        .notification-monitors {
          .notification-monitor {
            background: $main18;

            &:not(:last-child) {
              border-color: $main17;
            }

            .checkbox {
              .notification-checkbox {
                background: $main16;
              }

              &.active {
                .notification-checkbox {
                  background: $indigo1;
                }
              }
            }
          }
        }
      }
    }
  }

  .agent-monitoring {
    &__title, &__desc {
      color: $white;
    }

    &__block-title, &__block-text {
      color: $white;
    }

    &__share {
      color: $main5;
    }
  }

  .notification {

    &__back {

      .icon {
        fill: $white;
      }
    }
  }

  .profile-menu {
    background: $main19;
  }

  .profile-messages {

    .phone-block {
      background: $main18;
    }
  }

  .help-form {
    .t-headline-2 {
      color: $white;
    }
  }

  .menu-fixed {
    background: $main18;
    border-top: 1px solid $main17;

    &__icon {
      .icon {
        fill: $white;
      }
    }

    &__text {
      color: $white;

      &--blue {
        color: $indigo;
      }
    }
  }
}