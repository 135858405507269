.invite-project {
  height: 100%;

  &__bg {
    position: fixed;
    z-index: 10;
    background: rgba(42, 43, 58, 0.6);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
  }

  &__link {
    border-bottom: thin solid #2A2B3A;
  }

  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @include media('mobile') {
      right: 16px;
      top: 16px;
    }

    .icon {
      display: flex;
    }
  }

  &__popup {
    background: #fff;
    max-width: 700px;
    padding: 64px;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media('mobile') {
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      transform: unset;
      border-radius: 16px 16px 0 0;
      padding: 40px 16px 40px 16px;
    }
  }

  &__title {
    margin-bottom: 25px;

    @include media('mobile') {
      margin-bottom: 16px;
    }
  }

  &__desc {
    margin-bottom: 48px;
    color: #9999A2;

    @include media('mobile') {
      margin-bottom: 36px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
  }

  &__sign-in {
    display: flex;
    align-items: center;
    max-width: max-content;
    padding: 15px 24px 15px 21.5px;
    border-radius: 8px;
    transition: .4s ease;
    margin-right: 8px;
    min-width: 115px;
    justify-content: center;
    cursor: pointer;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      color: #fff;
    }
  }

  &__sign-up {
    padding: 16px 34.5px;
    margin: 0;

    &:hover {
      color: #2A2B3A;
    }
  }
}