.select-monitoring {
  width: 564px;

  @include media('mobile') {
    width: 100%;
    margin-bottom: 114px;
  }

  .t-headline-2 {
    text-align: center;

    @include media('mobile') {
      text-align: left;
    }
  }

  .t-headline-3 {
    margin-top: 64px;

    @include media('mobile') {
      margin-top: 48px;
    }
  }

  .field {
    margin-top: 24px;

    &.error {
      .field-text {
        position: absolute;
        bottom: -15px;
      }
    }
  }

  .button {
    margin-top: 48px;
  }

  .other-monitoring {
    width: 564px;
    padding: 16px 24px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;

    @include media('mobile') {
      width: 100%;
      padding: 16px;
      align-items: flex-start;
    }

    &__icon1 {
      width: 48px;
      height: 48px;
      flex-shrink: 0;

      @include media('mobile') {
        display: none;
      }

      .icon {
        width: 48px;
        height: 48px;
        fill: #2A2B3A;
      }
    }

    &__icon2 {
      .icon {
        fill: #2A2B3A;
      }
    }

    &__info {
      flex-grow: 1;
      margin: 0 32px;

      @include media('mobile') {
        margin: 0;
      }

      .description {
        color: $main8;
        margin-top: 8px;
      }
    }

    &__icon2 {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      transform: scale(-1, 1);

      @include media('mobile') {
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }
  }
}