.profile-account {
  margin: -24px;
  padding-bottom: 48px;

  @include media('mobile') {
    margin: 0;
  }

  .account-email, .account-password, .account-appearance {
    padding: 24px;

    @include media('mobile') {
      padding: 0 0 16px 0;

    }

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .account-email {
    .t-subtitle-2 {
      color: $main5;
      margin: 24px 0;

      @include media('mobile') {
        margin: 16px 0;
      }
    }
  }

  .account-appearance {
    .options {
      top: auto;
      bottom: 56px;
    }
  }

  .button {
    margin: 24px 0 0;

    @include media('mobile') {
      margin-top: 8px;
    }
  }
}