.accordion {
  &__btn {
    padding: 16px;
    cursor: pointer;

    &-first,
    &-last {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-item {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-desc {
      color: $main10;
    }

    &-price {
      display: flex;
      align-items: center;

      &_plus {
        color: $cyan;
      }
    }

    &-status {
      padding: 0 4px;
      margin-right: 8px;
      border-radius: 2px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;

      &_fail {
        background: $red;
        border-radius: 2px;
        color: $white;
      }
    }
  }

  &__list {
    background: $main1;
    padding: 16px;
    display: none;

    &.is-open {
      display: block;
    }
  }
}