.main-content-notify {
  background: $main1;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 128px 0;

    @include media('tablet') {
      display: block;
      margin: 0 auto;
    }
  }

  &__col {
    width: 50%;
    margin-right: 122px;

    @include media('tablet') {
      margin: 0 auto;
      margin-bottom: 64px;
      width: 100%;
    }

    &:first-of-type {
      padding-left: 98px;

      @include media('tablet') {
        padding-left: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
      min-width: 568px;

      @include media('tablet') {
        margin: 0 auto;
        margin-bottom: 0;
        min-width: auto;
      }
    }

    .grid {

      &__row {
        display: flex;
        margin-bottom: 24px;

        @include media('mobile') {
          flex-wrap: wrap;

          &:nth-child(2n + 2) {
            margin: 0;
          }
        }

        ~.hidden_tablet {
          @include media('tablet') {
            display: none;
          }
        }

        &:last-of-type {
          margin-bottom: 0;

          .grid__item {
            max-width: none;
          }
        }
      }

      &__item {
        background: $main2;
        border-radius: 8px;
        padding: 24px;
        flex: 1 1 auto;
        margin-right: 24px;
        max-width: 172px;

        @include media('mobile') {
          flex: none;
          width: calc((100% - 16px) / 2);
          margin-right: 16px;
          margin-bottom: 16px;

          &:nth-child(2n + 2) {
            margin: 0;
            margin-bottom: 16px;
          }
        }

        ~.show_tablet {
          display: none;

          @include media('tablet') {
            display: flex;
          }
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &__block {
        margin: 0 auto;
      }

      &__inner {
        display: flex;
      }

      &__content {
        max-width: 388px;
        margin: auto 0;
        padding-left: 68px;
      }

      &__icon {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }

      &__text {
        text-align: center;
      }
    }
  }

  &__item {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    @include media('tablet') {
      text-align: center;
    }
  }

  &__desc {
    color: $main8;

    @include media('tablet') {
      text-align: center;
    }
  }
}
