.block-copy {
  background: $main18;
  border-radius: 8px;
  padding: 16px;
  margin-top: 5px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 4px 10px 4px 25px;
    margin-bottom: 4px;

    &-text {
      color: $white;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    &:before {
      position: absolute;
      content: "$";
      color: $main5;
      margin-right: 8px;
      left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    &__json {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 4px 10px 4px 10px;
      margin-bottom: 4px;

      &:before {
        display: none;
      }

      &-text {
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &_copy {
        background: #323343;
        border-radius: 4px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &_copy {
      background: #323343;
      border-radius: 4px;
    }
  }

  &__copy {
    display: flex;
    align-items: center;

    .icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &-text {
      margin: 0;
      color: $white;
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
