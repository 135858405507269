.notification {
  width: 400px;
  height: calc(100vh - 80px);
  flex-shrink: 0;
  background-color: $white;
  position: fixed;
  top: 80px;
  right: -400px;
  padding: 30px 24px 24px;
  transition: 0.5s ease;
  z-index: 100;

  @include media('mobile') {
    width: 100%;
    top: 0;
    right: -100%;
    height: 100%;
    padding: 0 16px 16px;
  }

  &__back {
    display: none;

    @include media('mobile') {
      width: 56px;
      display: flex;
      align-items: center;
      height: 56px;
    }
  }

  .tabs {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  &__container {
    height: calc(100% - 105px);
    overflow-y: auto;
    margin-top: 32px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    @include media('mobile') {
      margin-top: 32px;
      height: calc(100% - 200px);
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &__date {
    color: $main8;
    margin-bottom: 16px;
    margin-top: 16px;

    @include media('mobile') {
      padding-left: 16px;
    }

    //&:first-of-type {
    //  margin-top: 0;
    //}
  }
  &__header {
    display: inline-flex;
    margin-bottom: 4px;
    align-items: center;
    width: 100%;
    position: relative;
    .t-headline-4 {
      margin-left: 6px;
      width: 100%;
      padding-right: 24px;
    }
  }
  &__help {
    width: 20px;
    height: 20px;
    &:last-of-type {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
  &__item {
    padding: 16px 18px;
    border-bottom: 1px solid $main1;
    &_new {
      position: relative;
      border-bottom: 1px solid $main1;
      &:last-of-type {
        border-bottom: thin solid $red;

        &:after {
          position: absolute;
          bottom: -12px;
          left: 50%;
          transform: translateX(-50%);
          content: attr(data-count);
          background: $red;
          border-radius: 4px;
          color: $white;
          padding: 2px 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.0025em;
        }
      }
    }
  }
  &__body {
    padding-left: 30px;
    .t-caption {
      color: $main8;
      margin-bottom: 8px;
    }
    .t-subtitle-2 {
      margin-bottom: 8px;
    }
  }
  &__description {
    .t-subtitle-2 {
      display: inline-block;
      color: $main8;
      &:not(:last-child) {
        &::after {
          content: '·';
          display: inline-block;
          margin: 0 4px;
        }
      }
    }
  }
  &.show {
    right: 0;
  }
}