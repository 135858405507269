.sign-up {
  .form {
    &__agreement {
      margin: 48px 0 -24px 0;
      color: $main8;
      a {
        color: $main18;
      }
    }
  }
}