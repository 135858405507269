.ant-picker {

  &-dropdown {

    &-placement-bottomLeft {
      .ant-picker-range-arrow {
        display: none;
      }
    }
  }

  &-date-panel {
    .ant-picker-content {
      width: 100%;
    }
  }

  &-date {

    &-panel {
      width: 323px;
    }
  }

  &-panel {

    &-container {
      filter: drop-shadow(0px 4px 20px rgba(42, 43, 58, 0.1));
      border-radius: 8px;
    }
  }

  &-header {
    border-bottom: none;
    padding: 7px 12px;
  }
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
  border-radius: 4px 0 0 4px !important;
}

.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 0 4px 4px 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  height: 29px;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-picker-header-view {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 4px;
}