.auth {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  &-main {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    &__first-column {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .sign-in, .sign-up {
        width: 388px;

        @include media('mobile') {
          width: 100%;
          max-width: 375px;
          padding: 0 16px;
        }

        .subtitle {
          margin-top: 24px;
          p {
            display: inline-block;
            margin-bottom: 0;
          }
          .t-subtitle-2 {
            color: #9999A2;
            margin-bottom: 0;
          }
          .t-button {
            margin-left: 4px;
            margin-bottom: 0;
          }
        }
        .form {
          margin-top: 48px;
          .field {
            margin-top: 24px;
          }
          .button {
            width: 100%;
            margin-top: 48px;
          }
        }
      }
    }
    &__second-column {
      width: 588px;
      height: 100%;
      border-radius: 32px;
      background-image: url("../../../assets/icons/auth-back.svg");
      background-size: cover;
      background-repeat: no-repeat;
      flex-shrink: 0;

      @include media('mobile') {
        display: none;
      }
    }
  }
  .popup__back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 36px;
    left: 36px;
    cursor: pointer;

    @include media('mobile') {
      top: 20px;
      left: 20px;
    }

    .icon {
      margin-right: 8px;

      @include media('mobile') {
        margin-right: 0;
      }
    }

    .t-button {

      @include media('mobile') {
        display: none;
      }
    }
  }
  .popup__close {
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 36px;

    @include media('mobile') {
      display: none;
    }
  }
}