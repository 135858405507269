.main-content-information {
  background: $white;
  padding: 128px 0;
  color: $white;

  &__inner {
    background: $main18;
    border-radius: 16px;
    padding: 128px 0;
  }

  &__item {
    margin: 0 auto;
    margin-bottom: 32px;
    max-width: 368px;

    &:first-of-type {
      margin-bottom: 64px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    text-align: center;
  }

  &__button {
    margin: 0 auto;
    padding: 16px 24px;
  }

  &__desc {
    text-align: center;
  }
}
