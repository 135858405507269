.create-template {
  width: 564px;
  margin: 0 auto;

  @include media('mobile') {
    width: 100%;
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;

    @include media('mobile') {
      text-align: left;
    }
  }

  .title + .clue {
    text-align: center;

    @include media('mobile') {
      text-align: left;
    }
  }

  .clue {
    margin-top: 24px;
    color: $main5;
    line-height: 1.4;
    white-space: pre-line;

    @include media('mobile') {
      margin-top: 16px;
      line-height: 16px;
    }
  }

  .pre-input {
    text-align: left;
    margin: 64px 0 24px;

    @include media('mobile') {
      margin: 48px 0 16px;
    }
  }

  .f-title {
    margin-top: 16px;

    @include media('mobile') {
      margin-top: 16px;
    }
  }

  .f-server-address {
    margin-top: 32px;
  }

  .server-address-test {
    height: 48px;
    margin-top: 8px;
    display: flex;
    align-items: center;

    @include media('mobile') {
      flex-wrap: wrap;
      height: auto;
    }

    .button {
      flex-shrink: 0;
      margin: 0;

      @include media('mobile') {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    .alert {
      margin-left: 8px;

      @include media('mobile') {
        width: 100%;
        margin-left: 0;
        flex-direction: revert;
        justify-content: flex-start;

        &__text {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
