.input-wrapper {
  height: 48px;
  position: relative;
  &.input-with-select {
    input {
      padding-left: 116px;
    }
    .select-wrapper {
      .select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .select-wrapper {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
  }
  input {
    min-width: 200px;
    width: 100%;
    height: inherit;
    border: 1px solid $main4;
    padding: 0 44px 0 16px;
    border-radius: 8px;
    color: $main18;
    outline: none;
    &::placeholder {
      color: $main8;
    }
    &:hover {
      border: 1px solid $main8;
    }
    &:focus {
      min-width: 199px;
      border: 2px solid $main18;
    }
    &.with-icon {
      padding: 0 44px 0 44px;
    }
  }

  &.add {
    height: auto;
  }

  &__email {
    display: flex;
    align-items: baseline;
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 1px solid $main4;
    border-radius: 8px;
    flex-wrap: wrap;

    &-text {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }

    .popup-share-project__email {
      margin-left: -4px;
      margin-top: -4px;
      margin-right: 10px;
    }

    input {
      border: none;
      width: auto;
      padding: 0;
      border-radius: 0;

      &:hover {
        border: none;
      }

      &:focus {
        border: none;
      }
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -12px;
    svg {
      fill: $main8;
      transition: all 0.3s ease;
    }
  }
  input:focus ~ &__icon {
    svg {
      fill: $main18;
    }
  }
  input:not(:invalid) + &__icon {
    svg {
      fill: $main18;
    }
  }
  &__icon-right {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -12px;
    cursor: pointer;
    transition: all 0.3s ease;
    svg {
      transition: all 0.3s ease;
    }
    &.icon_clear {
      opacity: 0;
      visibility: hidden;
      svg {
        fill: $main18;
      }
    }
    &.icon_hidden {
      svg {
        fill: $main4;
      }
    }
  }
  input:focus ~ &__icon-right {
    &.icon_clear {
      opacity: 1;
      visibility: visible;
    }
    &.icon_hidden {
      svg {
        fill: $main18;
      }
    }
  }
}
