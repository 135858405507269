.select-project {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;

  .button-add-project {
    min-width: 48px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main2;
    border-radius: 8px;
    margin-left: 8px;

    .icon {
      fill: $main18;
    }
  }
}