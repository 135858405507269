.main-content-block {

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 128px 0;

    @include media('tablet') {
      display: block;
    }
  }

  &__col {
    width: 50%;
    margin-right: 31px;

    @include media('tablet') {
      margin: 0 auto;
    }

    @include media('mobile') {
      width: 100%;
    }

    img {
      width: 100%;
    }

    &:last-of-type {
      margin-right: 0;

      @include media('tablet') {
        margin: 0 auto;
      }
    }
  }

  &__item {
    margin-bottom: 64px;

    &--buttons {
      max-width: 428px;
      margin: 0 auto;
      padding: 24px 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    max-width: 368px;
    text-align: center;
    margin: 0 auto;
  }

  &__desc {
    max-width: 368px;
    text-align: center;
    color: $main8;
    margin: 0 auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__svg {
    width: 100%;
  }

  &__version {
    color: $main8;
  }

  &__android {
    margin-right: 24px;
  }
}