.tabs {
  display: flex;
  margin-top: 12px;

  @include media('mobile') {
    margin-top: 16px;
  }

  .tab {
    padding: 8px 16px;
    margin-right: 8px;
    white-space: nowrap;

    &.active {
      color: $white;
      background-color: $main18;
      border-radius: 8px;
    }
  }
}