.app {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 80px;

  @include media('mobile') {
    padding-top: 0;
  }

  &_auth {
    padding-top: 0;
  }

  &-container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding-left: $sidebarWidth;
    background-color: #F8F8F8;

    @include media('mobile') {
      padding-left: 0;
    }

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .inner {
        width: 100%;
        padding: 24px 34px;

        @include media('mobile') {
          padding: 0 16px 114px;
        }

        .project-dropdown {
          display: none;
          width: 200px;
          height: auto;
          padding: 0;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 50%;
          top: 168px;
          transform: translate(-50%, 0);
          z-index: 5;
          background-color: $white;
          box-shadow: 0 4px 20px rgba(42, 43, 58, 0.1);
          border-radius: 8px;
          transition: all 0.3s ease;

          @include media('mobile') {
            display: block;
          }

          div {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              background-color: $main1;
            }
          }

          &.dropdown {
            padding: 8px 0;
            opacity: 1;
            visibility: visible;
          }
        }

        .tabs {
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
            width: 0;
          }

          .tab {
            display: flex;
            align-items: center;
            padding: 0;

            &.active {

              .icon {
                fill: $white;
              }
            }

            span {
              padding: 8px 16px;
            }

            div {
              width: 32px;
              height: 100%;
              display: none;
              align-items: center;
              justify-content: center;
              border-left: thin solid white;

              @include media('mobile') {
                display: flex;
              }
            }

            .icon {
              display: none;
              width: 3px;
              height: 15px;

              @include media('mobile') {
                display: block;
              }
            }
          }
        }

        .top-line {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .new-monitoring {
            @include media('mobile') {
              display: none;
            }
          }
        }

        .monitors {
          display: flex;
          flex-wrap: wrap;
        }
      }

      &--profile {
        background-color: #fff;
        height: 100vh;

        @include media('mobile') {
          height: calc(100vh - 88px - 56px);
        }

        .inner {
          @include media('mobile') {
            padding: 0 16px;
          }
        }
      }
    }
  }
}