.status-info {
  background: $white;
  border: 1px solid $main1;
  border-radius: 8px;
  padding: 24px;

  &__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;

    span {
      display: block;
      margin-left: 16px;
      color: #9999A2;
    }
  }

  &__check {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $main8;
  }

  &__wrapper {
    display: flex;
  }

  &__item {
    border: 1px solid $main2;
    border-radius: 8px;
    padding: 16px;
    width: calc((100% - 16px) / 2);
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__icon {
    margin-bottom: 8px;
  }

  &__number {
    margin-bottom: 4px;
  }

  &__desc {
    color: $main8;
  }
}