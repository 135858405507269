.menu-mobile {
  position: fixed;
  background: $white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px;
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: .4s ease;

  &__close {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__item {
    margin-bottom: 24px;

    &--btn {
      margin-top: 48px;
      margin-bottom: 0;
    }
  }

  &__link {
    text-align: center;
  }

  &__btn {
    margin: 0 auto;
    padding: 16px 24px;
  }

  &--active {
    pointer-events: all;
    opacity: 1;
    transition: .4s ease;
  }
}