.profile {
  width: 760px;
  margin: 48px auto;

  @include media('mobile') {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 10;
    margin: 0;
  }

  &-content {
    display: flex;
    margin-top: 48px;

    @include media('mobile') {
      padding-bottom: 88px;
      margin-top: 0;
    }
  }

  &-blocks {
    width: 100%;
    padding: 24px;

    @include media('mobile') {
      padding: 16px;
      padding-top: 0;
    }
  }

  .buttons-row {
    display: flex;

    .button {
      margin: 0;
    }

    .button:not(:first-child) {
      margin-left: 16px;

      @include media('mobile') {
        margin-left: 8px;
      }
    }
  }

  .t-label-2 {
    margin-bottom: 4px;
  }

  .mt24 {
    margin-top: 24px;

    @include media('mobile') {
      margin-top: 16px;
    }
  }

  .mb16 {
    margin-bottom: 16px;

    @include media('mobile') {
      margin-bottom: 8px;
    }
  }

  .upload-file {
    position: relative;

    input[type="file"] {
      display: none;
    }
  }

  .profile-tabs {
    @include media('mobile') {
      display: none;
    }
  }

  .profile-back {
    display: none;
    padding: 16px 0;
    margin-bottom: 16px;
    width: max-content;

    @include media('mobile') {
      display: flex;
      align-items: center;
    }

    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    .icon {
      margin-right: 8px;
    }
  }
}