body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: $main18;
  overflow-x: hidden;
}

.icon-text {
  width: 100%;
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
}

.g-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.color0 {
  background-color: $red;
}

.color1 {
  background-color: $volcano;
}

.color2 {
  background-color: $orange;
}

.color3 {
  background-color: $gold;
}

.color4 {
  background-color: $yellow;
}

.color5 {
  background-color: $lime;
}

.color6 {
  background-color: $green;
}

.color7 {
  background-color: $cyan;
}

.color8 {
  background-color: $indigo;
}

.color9 {
  background-color: $purple;
}

.color10 {
  background-color: $magenta;
}

.color11 {
  background-color: $brown;
}

.color12 {
  background-color: $blueGray;
}