.agent-monitoring {
  width: 564px;

  @include media('mobile') {
    width: 100%;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;

    @include media('mobile') {
      text-align: left;
      margin-bottom: 16px;
    }
  }

  &__desc {
    text-align: center;
    color: $main5;

    @include media('mobile') {
      text-align: left;
    }
  }

  &__block {
    margin-top: 64px;

    @include media('mobile') {
      margin-top: 48px;
    }

    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &-text {
      color: $main5;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-inner {
      margin-bottom: 24px;
    }

    .select-project {
      margin-bottom: 16px;
    }

    .server-address-test {
      align-items: center;
      display: flex;
      height: 48px;
      margin-top: 8px;

      @include media('mobile') {
        flex-wrap: wrap;
        height: auto;
      }

      .button {
        flex-shrink: 0;

        @include media('mobile') {
          width: 100%;
          margin-bottom: 8px;
        }
      }

      .alert {
        margin-left: 8px;

        @include media('mobile') {
          width: 100%;
          margin-left: 0;
          flex-direction: revert;
          justify-content: flex-start;

          &__text {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .button {
      margin: 0;
      float: right;

      &.last {
        @include media('mobile') {
          float: unset;
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }

    .tabs {
      margin-bottom: 4px;
    }

    .tab {
      color: $main5;
      &.active {
        background-color: transparent;
        color: $main18;
      }
    }
  }

  &__share {
    display: flex;
    align-items: center;
    color: $main18;
    cursor: pointer;
    line-height: 22px;

    .icon {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }
}