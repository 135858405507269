.profile-notifications {
  .search {
    margin: 24px 0;

    @include media('mobile') {
      margin: 16px 0;
    }
  }

  .notifications {
    .notification-block {
      &:not(:first-child) {
        margin-top: 24px;

        @include media('mobile') {
          margin-top: 16px;
        }
      }

      &-header {
        width: 100%;
        display: flex;
        justify-content: stretch;
        align-items: center;

        @include media('mobile') {
          margin-bottom: 8px;
        }

        .t-inter {
          margin: 16px;
          flex-grow: 1;
          width: auto;

          @include media('mobile') {
            margin: 0;
          }
        }

        .checkboxes {
          min-width: 55px;
          display: flex;
          align-items: center;

          &--two {
            min-width: 121px;
          }
          &--thre {
            min-width: 176px;
          }
        }

        .checkbox {
          flex-grow: 0;
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          &:last-child {
            margin-right: 19px;

            @include media('mobile') {
              margin-right: 11px;
            }
          }
        }
      }

      .notification-monitors {
        border-radius: 8px;
        overflow: hidden;

        .notification-monitor {
          width: 100%;
          padding: 18px 16px;
          background-color: $main1;
          display: flex;
          justify-content: stretch;
          align-items: center;

          @include media('mobile') {
            padding: 16px 9px 16px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $main2;
          }

          .t-inter {
            margin: 0;
            flex-grow: 1;
            width: auto;
          }

          .checkbox {
            flex-grow: 0;
            margin-right: 7px;

            .notification-checkbox {
              width: 48px;
              height: 24px;
              border-radius: 12px;
              background-color: $main3;
              display: block;
              position: relative;
              cursor: pointer;
              transition: all 0.3s ease;

              .circle {
                display: block;
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $white;
                position: absolute;
                top: 4px;
                left: 4px;
                transition: all 0.3s ease;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }

            &.active {
              .notification-checkbox {
                background-color: $blue;

                .circle {
                  left: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
}
