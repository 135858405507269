.name-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  border-radius: 50%;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  &.size {
    &_small {
      width: 24px;
      height: 24px;
      font-size: 12px;
    }
    &_medium {
      width: 32px;
      height: 32px;
      font-size: 14px;
    }
    &_big {
      width: 100px;
      height: 100px;
      font-size: 36px;
      font-weight: 500;
    }
  }
}