.status {
  background: $main1;
  padding: 48px 0 80px;
  width: 100%;

  &__header {
    width: 100%;
    background: #393B55;
    border-radius: 8px;
    padding: 35px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32%;
    height: 90%;
    object-fit: cover;
  }

  &__header-title {
    font-family: 'Leckerli One', cursive;
    font-weight: 400;
    font-size: 64px;
    line-height: 86px;
    color: $white;
  }

  &__header-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
    width: 34%;
  }

  &__container {
    max-width: 850px;
    margin: 0 auto;
    width: 100%;
  }

  .status-alert {
    margin-bottom: 24px;
  }

  .status-info {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}