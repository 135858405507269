.header {
  width: calc(100% - 300px);
  height: 80px;
  background-color: $white;
  border-bottom: 1px solid $main1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px;
  position: fixed;
  top: 0;
  z-index: 9;

  @include media('mobile') {
    justify-content: flex-end;
    position: unset;
    width: 100%;
    background-color: #f8f8f8;
    padding: 0 16px;
    height: 56px;
    border-bottom: none;
  }

  .search {
    width: 100%;
    flex-grow: 1;
    margin-left: -12px;

    @include media('mobile') {
      display: none;
    }

    input {
      border: none;
      width: calc(100% + 12px);
    }
  }

  .notification-icon {
    width: 24px;
    height: 24px;
    margin: 0 32px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;

    @include media('mobile') {
      margin: 0;
    }

    .icon {
      fill: $main18;
    }

    .counter {
      width: 13px;
      height: 13px;
      background-color: $red;
      border-radius: 50%;
      font-size: 8px;
      font-weight: 700;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .user-dropdown {
    white-space: nowrap;
  }

  .user {
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    @include media('mobile') {
      display: none;
    }

    span {
      margin: 0 8px;
    }

    .icon {
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }

    &-dropdown {
      height: auto;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 88px;
      right: 0;
      background-color: $white;
      box-shadow: 0 4px 20px rgba(42, 43, 58, 0.1);
      border-radius: 8px;
      transition: all 0.3s ease;

      a {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 32px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: $main1;
        }
      }
    }

    &.dropdown {
      .icon {
        transform: rotate(0);
      }

      .user-dropdown {
        padding: 8px 0;
        opacity: 1;
        visibility: visible;
        z-index: 3;
      }
    }
  }
}