.alert {
  width: 100%;
  height: 112px;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  align-items: center;

  @include media('mobile') {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: unset;
    padding: 17px 16px;
    height: auto;
    margin-top: 16px;
  }

  &_warning {
    background-color: rgba($red, 0.1);

    .alert__icon {
      .icon {
        fill: $red;
      }
    }

    .alert__text {
      color: $red;

      @include media('mobile') {
        margin-left: 0;
      }
    }
  }

  &_good {
    background-color: $main1;

    .alert__icon {
      .icon {
        fill: $main18;
      }
    }

    .alert__text {
      color: $main18;

      .t-body-2 {
        color: $main8;
      }
    }
  }

  &_default {
    align-items: center;

    //.alert__text {
    //  @include media('mobile') {
    //    ali
    //  }
    //}
  }

  &_success {
    background-color: rgba($green, 0.1);

    @include media('mobile') {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .alert__text {
      color: $green;
    }

    .alert__icon {
      .icon {
        fill: #52C41A;
      }
    }
  }

  &_success-default {
    background-color: $main1;
    .alert__text {
      color: $main10;
    }
    .alert__icon {
      .icon {
        fill: $main10;
      }
    }
  }

  &__icon {
    width: 64px;
    height: 64px;

    .icon {
      width: 64px;
      height: 64px;
    }
  }

  &__text {
    margin-left: 24px;

    .t-body-2 {
      margin-top: 8px;
    }
  }

  &_small {
    height: 48px;
    padding: 12px;
    margin-top: 0;
    font-size: 14px;

    .alert__icon {
      width: 24px;
      height: 24px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    .alert__text {
      margin-left: 10px;

      .t-headline-3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
      }
    }
  }
}