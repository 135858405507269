.status-alert {
  border-radius: 8px;
  padding: 24px;

  &__title {

  }

  &__list {
    list-style: disc;
    margin: 8px 0 0;
    padding-left: 24px;
  }

  &__list-item {
    color: rgba(245, 34, 45, 0.6);
  }

  &.success {
    background: rgba(82, 196, 26, 0.1);

    .status-alert {

      &__title {
        color: $green;
      }
    }
  }

  &.error {
    background: rgba(245, 34, 45, 0.1);

    .status-alert {

      &__title {
        color: $red;
      }
    }
  }
}