.select-wrapper {
  width: 100%;
  height: 48px;
  position: relative;
  .select {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border: 1px solid $main4;
    border-radius: 8px;
    cursor: pointer;
  }
  .icon-arrow {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    .icon {
      fill: $main18;
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }
  }
  .options {
    width: 100%;
    height: auto;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 56px;
    left: 0;
    background: $white;
    box-shadow: 0 4px 20px rgba(42, 43, 58, 0.1);
    border-radius: 8px;
    z-index: 2;
    transition: all 0.3s ease;
    .option {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      padding: 0 16px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background-color: $main1;
      }
    }
  }
  &.dropdown {
    .icon-arrow {
      .icon {
        transform: rotate(0);
      }
    }
    .options {
      padding: 8px 0;
      opacity: 1;
      visibility: visible;
    }
  }
}