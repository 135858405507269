.scroll {
  width: 2px;
  height: calc(100% - 143px);
  background-color: $main1;
  position: absolute;
  top: 122px;
  right: 10px;
  .scrollBar {
    width: 2px;
    height: 20px;
    background-color: $main4;
    position: absolute;
    top: 0;
  }
}