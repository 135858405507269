.monitor-page {
  .top-line {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media('mobile') {
      height: auto;
    }

    .back {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      @include media('mobile') {
        position: absolute;
        top: 0;
        height: 56px;
        width: 56px;
        justify-content: flex-start;
      }
    }

    .monitor-info {
      width: 100%;
      height: 48px;
      margin-left: 8px;
      flex-grow: 1;

      @include media('mobile') {
        margin-left: 0;
        height: auto;
      }
    }

    .notification-setting {
      padding: 12px 20px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      @include media('mobile') {
        display: none;
      }

      .t-button {
        margin-left: 8px;
      }
    }

    .monitor-options {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin-left: 8px;
      cursor: pointer;

      @include media('mobile') {
        display: none;
      }

      .icon {
        fill: $main18;
      }
    }
  }

  .line-information {
    margin-top: 24px;
    background: $white;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: thin solid $main1;
    border-radius: 8px;
    position: relative;

    @include media('mobile') {
      display: block;
      margin-top: 16px;
      padding: 16px;
    }

    &__item {
      display: flex;
      align-items: center;

      @include media('mobile') {
        flex-wrap: wrap;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      svg {
        margin-left: 8px;
        width: 16px;
        height: 16px;
        fill: $main18;
      }
    }

    &__icon {
      position: absolute;
      top: 13px;
      right: 7px;
      display: none;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      @include media('mobile') {
        display: flex;
      }

      .icon {
        width: 8px;
        height: 24px;
        margin: 0;
      }
    }

    &__col {
      &:first-letter {
        text-transform: uppercase;
      }

      &:not(:last-child) {
        &::after {
          content: '·';
          margin: 0 4px;
        }
      }
    }
  }

  .monitor-sync {
    padding: 24px;
    background: $white;
    border: 1px solid #F2F2F3;
    border-radius: 8px;
    margin-top: 24px;

    &__title {
      margin-bottom: 24px;
    }

    &__desc {
      margin-bottom: 24px;
      color: #9999A2;
    }

    .button {
      padding: 8px 16px;
      height: auto;
    }
  }

  .monitor-blocks {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 24px;
      background-color: $white;
      padding: 24px;
      border-radius: 8px;
      position: relative;

      @include media('mobile') {
        padding: 16px;
        margin-top: 16px;
      }
    }

    .overview {
      width: 100%;

      > .t-headline-4 {
        margin-bottom: 16px;

        span {
          display: inline-flex;
          margin-left: 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: $main8;
        }
      }

      @include media('mobile') {
        height: auto;
      }
    }

    .overview-content {
      display: flex;
      padding: 16px 0;
    }

    .overview-col {
      width: calc(100% - 16px);
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .overview-line {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #F2F2F3;

      > .t-label-1 {
        margin-bottom: 8px;
      }

      &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .overview-line-wrapper {
      display: flex;
    }

    .overview-block {
      padding-right: 16px;
      margin-right: 16px;

      .button {
        margin: 0;
      }

      .block__value {
        margin-bottom: 4px;

        &.green {
          color: $green;
        }

        &.gold {
          color: $gold;
        }

        &.red {
          color: $red;
        }
      }

      .block__name {
        color: $main8;

        @include media('mobile') {
          margin-top: 4px;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.center {
        display: flex;
        align-items: center;
      }
    }

    .hardware {
      margin-left: 24px;

      @include media('mobile') {
        margin-left: 0;
      }
    }

    .uptime {
      width: 100%;

      .uptime-chart {
        margin: 24px 0 16px;
        display: flex;
        justify-content: flex-start;

        @include media('mobile') {
          flex-wrap: wrap;
          justify-content: unset;
          margin: 4px 0;
        }

        .chart-day {
          max-width: 45px;
          width: 100%;
          border-radius: 2px;
          background: $main1;
          border: 1px solid $main2;

          div {
            padding-bottom: 100%;
          }

          @include media('mobile') {
            width: calc((100% - (4px * 9 * 2)) / 9);
            margin: 4px;
          }

          &:not(:first-child) {
            margin-left: 8px;

            @include media('mobile') {
              margin-left: 4px;
            }
          }

          &.green {
            background: rgba($green, 0.2);
            border: 1px solid $green;
          }

          &.gold {
            background: rgba($gold, 0.2);
            border: 1px solid $gold;
          }

          &.red {
            background: rgba($red, 0.2);
            border: 1px solid $red;
          }
        }
      }

      .uptime-chart-date {
        display: flex;
        justify-content: space-between;

        .day-start, .day-end {
          font-size: 12px;
          color: $main8;
        }
      }
    }

    .latency {
      width: 100%;

      canvas {
        width: 100% !important;
        height: 150px !important;
      }

      .t-headline-4 {
        margin-bottom: 24px;
      }
    }

    .cpu, .ram, .network, .disk, .disk-use {
      width: calc(50% - 12px);
      height: 255px;
    }

    .cpu, .ram, .disk, .network, .disk-use {
      canvas {
        width: 100% !important;
        height: 150px !important;
      }

      .t-headline-4 {
        margin-bottom: 24px;

        span {
          display: inline-flex;
          margin-left: 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: $main8;
        }
      }
    }

    .ram {
      margin-left: 24px;
    }

    .network {
      margin-left: 24px;
    }

    .disabled-popup {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(2px);
      padding: 0 32px;

      @include media('mobile') {
        padding: 0 16px;
      }

      .inner {
        width: 100%;
        padding: 24px;
        background-color: $main18;
        border-radius: 8px;

        .t-headline-3 {
          color: $white;
        }

        .t-body-2 {
          color: $main8;
          margin: 24px 0;
        }
        .button_bc-white {
          &.disabled {
            opacity: .4;
            color: black;
          }
        }
      }
    }
  }

  &--stopped {
    .line-information__col--sync {
      color: #FA8C16;
    }

    .monitor-blocks {
      .block-line {
        > div {
          .block__value {
            color: #9999A2 !important;
          }
        }
      }
    }
  }

  &--paused {
    .line-information__col--sync {
      color: #F5222D;
    }

    .monitor-blocks {
      .block-line {
        > div {
          .block__value {
            color: #9999A2 !important;
          }
        }
      }
    }
  }
}
