.main-content-tutorial {
  padding: 128px 0;
  background: $white;

  &__item {
    margin-bottom: 128px;
    display: flex;
    align-items: center;

    @include media('tablet') {
      display: block;
    }

    &:last-of-type {
      margin-bottom: 0;

      @include media('mobile') {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  &__col {
    flex: 1;
  }

  &__title {
    margin:  0 auto;
    margin-bottom: 32px;
    max-width: 368px;

    @include media('tablet') {
      max-width: none;
      text-align: center;
    }
  }

  &__desc {
    margin:  0 auto;
    max-width: 368px;
    color: $main8;

    @include media('tablet') {
      max-width: none;
      text-align: center;
    }
  }

  &__svg {
    @include media('tablet') {
      margin-bottom: 64px;
    }

    img {
      margin: 0 auto;
      display: block;

      @include media('mobile') {
        width: 100%;
      }
    }
  }
}