.menu-fixed {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background: #fff;
  height: 88px;
  align-items: center;
  border-top: 1px solid #F2F2F3;

  @include media('mobile') {
    display: flex;
  }

  &__item {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    display: flex;
    margin-bottom: 2px;
    position: relative;
  }

  &__event-count {
    width: 13px;
    height: 13px;
    background-color: $red;
    border-radius: 50%;
    font-size: 8px;
    font-weight: 700;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__text {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: $main18;

    &--blue {
      color: $indigo;
    }
  }
}