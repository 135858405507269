.auth-popup {
  width: 368px;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include media('mobile') {
    //position: relative;
    width: 100%;
    max-width: 375px;
    padding: 0 16px;
    justify-content: unset;
    align-items: unset;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('mobile') {
      order: 2;
      flex: 1;
    }
  }

  &__icon {
    width: 200px;
    height: 200px;
    &.confirm-email {
      background-image: url("../../../assets/icons/confirm-email.svg");
    }
    &.account-ready {
      background-image: url("../../../assets/icons/circle-access.svg");
    }
  }
  .t-headline-2 {
    margin-top: 48px;
    text-align: center;

    @include media('mobile') {
      text-align: left;
      margin-top: 56px;
    }
  }
  .t-subtitle-2 {
    text-align: center;
    margin-top: 24px;
    color: $main8;

    @include media('mobile') {
      text-align: left;
    }

    a, span {
      color: $main18;
    }
  }
  .field {
    margin-top: 48px;
  }
  .button {
    width: 100%;
    margin-top: 48px;

    @include media('mobile') {
      order: 3;
      margin-bottom: 24px;
    }
  }
}