.profile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 88px;
  //height: calc(100vh - 88px);
  width: 100%;
  z-index: 10;
  background: $white;
  display: none;
  padding: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  @include media('mobile') {
    display: block;
  }

  .profile__main-info {
    display: flex !important;
    flex-direction: column;
    margin-bottom: 32px;

    .main-info {

      &__second-column {
        margin-left: 0;
      }

      &__name {
        font-size: 24px;
        line-height: 32px;
        margin: 16px 0 8px 0;
        text-align: center;
      }

      &__email {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
  }

  .profile-tabs {
    width: 100%;

    .tab {
      justify-content: space-between;
      > div {
        display: flex;

        &:last-of-type {
          .icon {
            margin: 0;
          }
        }
      }
    }
  }
}