.icon {
  width: 24px;
  height: 24px;
  fill: $main18;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.logo-icon {
  width: 147px;
  height: 29px;
  background-image: url("../../assets/icons/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.dots-six {
  width: 14px;
  height: 24px;
}

.dots-free {
  width: 8px;
  height: 24px;
}
