$red: #F5222D;
$red1: #D92F38;
$volcano: #FA541C;
$orange: #FA8C16;
$gold: #FAAD14;
$yellow: #FADB14;
$lime: #A0D911;
$green: #52C41A;
$cyan: #13C2C2;
$blue: #1890FF;
$indigo: #2F54EB;
$indigo1: #2B47B7;
$indigo2: #2548D6;
$purple: #722ED1;
$magenta: #EB2F96;
$brown: #6D4C41;
$blueGray: #546E7A;
$main20: #1F1F2C;
$main19: #1B1B24;
$main18: #2A2B3A;
$main17: #343544;
$main16: #3E3F4D;
$main15: #494A57;
$main14: #545461;
$main13: #5F5F6C;
$main12: #6A6A76;
$main11: #767681;
$main10: #81828C;
$main9: #8D8D96;
$main8: #9999A2;
$main7: #A5A5AD;
$main6: #B2B2B8;
$main5: #BEBEC4;
$main4: #CBCBCF;
$main3: #D8D8DB;
$main2: #E5E5E7;
$main1: #F2F2F3;
$white: #FFFFFF;

$sidebarWidth: 300px;