.fspopup {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  padding: 48px 24px 24px;
  z-index: 15;

  @include media('mobile') {
    padding: 16px;
    padding-top: 56px
  }

  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    .fspopup-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 48px - 24px);
    }
  }
  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    .icon {
      margin-right: 8px;
    }

    .t-button {
      @include media('mobile') {
        display: none;
      }
    }
  }
  &__close {
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;

    @include media('mobile') {
      display: none;
    }
  }
}