.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    margin-bottom: 8px;
    padding-left: 8px;
    .required {
      color: $red;
    }
  }
  &-text {
    margin-top: 4px;
    padding-left: 8px;
    color: $main8;
  }
  &.error {
    label {
      color: red;
    }
    .input-wrapper {
      input, textarea {
        border: 1px solid $red;
        &:focus {
          border: 2px solid $red;
        }
      }
    }
    .field-text {
      color: $red;
    }

    .select {
      border-color: red;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
