.t-headline-1 {
  font-size: 47px;
  font-weight: 700;
  line-height: 70px;

  @include media('mobile') {
    font-size: 33px;
    line-height: 48px;
    letter-spacing: 0.0025em;
  }
}

.t-headline-2 {
  font-size: 33px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.0025em;
}

.t-headline-3 {
  font-size: 23px;
  font-weight: 500;
  line-height: 34px;
}

.t-headline-4 {
  font-size: 19px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.0015em;
}

.t-subtitle-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.0015em;

  @include media('mobile') {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
  }
}

.t-subtitle-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.001em;
}

.t-body-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.005em;

  @include media('mobile') {
    font-size: 14px;
    line-height: 20px;
  }
}

.t-body-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.t-label-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.0015em;
}

.t-label-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.001em;
}

.t-button {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.001em;
  cursor: pointer;

  @include media('mobile') {
    line-height: 16px;
  }
}

.t-input {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.001em;
}

.t-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.004em;
}

.t-overline {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0.015em;
}