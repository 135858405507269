.main-content-price {
  padding: 128px 0;
  background: $main18;

  &__inner {
    color: $white;
  }

  &__item {
    margin-bottom: 64px;

    a:hover {
      color: white;
    }

    &:last-of-type {
      margin-bottom: 0;

       .main-content-price__title {
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .grid {

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
      }

      &__item {
        width: calc(100% / 3);
        min-width: 320px;
        padding: 12px;

        @include media('tablet') {
          width: calc(100% / 2);
        }

        @include media('mobile') {
          width: 100%;
          min-width: auto;
          padding: 0 0 16px 0;
        }
      }

      &__block {
        padding: 24px;
        background: $main17;
        border-radius: 16px;
        transition: .2s ease;

        &_right {
          margin-right: 0;
        }

        &:hover {
          transform: translateY(-8px);
          transition: .2s ease;
          Box-shadow:0 0 0 2px $main14;
        }
      }

      &__header {
        margin-bottom: 44px;

        &-item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &__icon {
        margin-right: 8px;
      }

      &__body {

        &-item {
          display: flex;
          align-items: baseline;
          margin-bottom: 24px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &__price {
        margin-right: 4px;
      }

      &__month {
        color: $main8;
      }

      &__button {
        background: linear-gradient(135deg, #FEB692 0%, #EA5455 100%);
        border-radius: 8px;
        padding: 8px 16px;

        &:hover {
          background: linear-gradient(135deg, #EA5455 0%, #FEB692 100%);
        }
      }
    }

    .list {
      margin: 0;

      &__item {
        display: flex;
        align-items: center;
        height: 24px;
        margin-bottom: 12px;
        padding-left: 28px;
        position: relative;

        &:after {
          content: "";
          height: 24px;
          width: 24px;
          position: absolute;
          left: 0;
          background-image: url("../../assets/icons/check.svg");
          background-repeat: no-repeat;
          background-position: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 48px;
  }

  &__desc {
    text-align: center;
    color: $main8;
  }
}
