.ant-picker-dropdown-range {

  .ant-picker-panel-container {

    .ant-picker-panels {
      @include media('mobile') {
        display: flex;
        flex-direction: column;
      }
    }
  }
}