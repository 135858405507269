.profile-tabs {
  width: 172px;
  margin-right: 24px;
  flex-shrink: 0;
  a {
    display: block;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  .tab {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    cursor: pointer;
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    &:hover {
      background-color: rgba($main1, 0.5);
    }
    & > * {
      pointer-events: none;
    }
  }
  .active {
    .tab {
      background-color: $main1;
    }
  }
  .separators {
    width: 100%;
    height: 1px;
    background-color: #EEE;
    margin: 8px 0;
  }
}