.profile__main-info {
  display: flex;
  align-items: center;

  @include media('mobile') {
    display: none;
  }

  .main-info__first-column {
    .name-photo {
      width: 100px;
      height: 100px;
      font-size: 36px;
    }
  }

  .main-info__second-column {
    margin-left: 24px;

    .main-info__name {
      font-size: 36px;
      font-weight: 600;
    }

    .main-info__email {
      font-size: 14px;
      font-weight: 400;
    }
  }
}