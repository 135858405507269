.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .container {
    margin: 0 auto;
    max-width: 1152px;

    @include media('tablet') {
      max-width: 768px;
    }

    @include media('mobile') {
      max-width: 348px;
      padding: 0 16px;
    }
  }

  .hidden {
    @include media('mobile') {
      display: none;
    }

    &_tablet {
      @include media('tablet') {
        display: none;
      }
    }
  }

  .show {
    display: none;

    @include media('mobile') {
      display: block;
    }

    &_tablet {
      @include media('tablet') {
        display: block;
      }
    }
  }
}

.main-content {
  flex: 1;
}