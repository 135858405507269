.profile-messages {
  .t-headline-4 {
    margin-bottom: 24px;

    @include media('mobile') {
      margin-bottom: 16px;
    }
  }

  .t-body-2 {
    margin-bottom: 16px;

    @include media('mobile') {
      margin-bottom: 8px;
    }
  }

  .phone-block {
    width: 100%;
    padding: 16px;
    background-color: $main1;
    border-radius: 8px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .top-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .name {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .bottom-line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .use-as-default {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        cursor: pointer;
      }

      .default {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $main5;
        flex-grow: 1;
        text-align: center;

        @include media('mobile') {
          text-align: left;
        }
      }

      .remove {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: $red;
          margin-left: 4px;
        }
      }
    }
  }

  .buttons-row {
    margin-top: 16px;
    align-items: center;

    @include media('mobile') {
      margin-top: 8px;
      display: block;
    }
  }

  .t-subtitle-2 {
    color: #BEBEC4;
    max-width: 215px;
    margin-left: 16px;

    @include media('mobile') {
      margin: 8px 0 0;
      max-width: none;
    }
  }

  .telegram {
    margin-top: 72px;

    @include media('mobile') {
      margin-top: 48px;
    }
  }
}