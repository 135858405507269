.sidebar {
  width: $sidebarWidth;
  padding: 0 24px;
  height: 100vh;
  background-color: $main18;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;

  @include media('mobile') {
    display: none;
  }

  .logo {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu {
    margin: 24px 0;

    .link {
      a {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        color: $white;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        transition: all 0.3s ease;

        .icon {
          fill: $white;
        }

        &:hover, &.active {
          background-color: $main16;
        }
      }
    }

    &__footer {
      bottom: 0;
      position: fixed;
      width: 252px;
    }
  }

  .projects {
    color: $main8;
    margin-top: -8px;

    .active {
      .block {
        background-color: $main16;
      }
    }

    .block {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      margin-top: 8px;
    }

    .top-line {
      .projects__heading {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          color: $white;

          .icon {
            fill: $white;
          }
        }

        .icon {
          margin-left: 8px;
          fill: $main8;
        }
      }

      .projects__add {
        cursor: pointer;

        &:hover {
          .icon {
            fill: $white;
          }
        }

        .icon {
          fill: $main8;
        }
      }
    }

    &-blocks {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;

      .project {
        cursor: pointer;

        &:hover {
          background-color: $main16;

          .dots-free {
            opacity: 0.5;
          }
        }

        &.active {
          background-color: $main16;
        }

        .dots-six {
          cursor: grab;

          .icon {
            fill: $main8;
          }
        }

        .name-photo {
          margin-left: 8px;
          flex-shrink: 0;
        }

        .t-button {
          color: $white;
          text-align: left;
          flex-grow: 1;
          margin: 0 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
        }

        .dots-free {
          opacity: 0;
          transition: all 0.3s ease;

          &:hover {
            opacity: 1;
          }

          .icon {
            fill: $white;
          }
        }
      }
    }

    &.show {
      .projects__heading {
        .icon {
          transform: rotate(180deg);
        }
      }

      .projects-blocks {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .project-dropdown {
    width: 200px;
    height: auto;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -$sidebarWidth + 76px;
    z-index: 2;
    background-color: $white;
    box-shadow: 0 4px 20px rgba(42, 43, 58, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;

    div {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: $main1;
      }
    }

    &.dropdown {
      padding: 8px 0;
      opacity: 1;
      visibility: visible;
    }
  }
}

.popup-edit-project {
  .t-headline-4 {
    margin: 24px 0 12px;
  }

  .buttons {
    margin-top: 24px;
  }
}

.popup-delete-project {
  .t-body-2 {
    color: $main8;
    margin: 24px 0;
  }
}

.popup-share-project {

  .button {
    margin: 0;
    margin-bottom: 24px;
  }

  &__email {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 4px;
    background: $main2;
    margin-bottom: 10px;

    span {
      display: block;
    }

    .icon {
      margin-left: 6px;
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
  }

  .t-body-2 {

    &.no-invite {
      color: $main12;
    }
  }

  &__input {
    position: relative;
    margin-bottom: 24px;
  }

  &__link {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 2;
  }

  &__collaborators {
    &-title {
      margin-bottom: 12px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
    }

    &-user {
      display: flex;
      align-items: center;

      .t-body-2 {
        margin-left: 8px;
      }
    }
  }
}
