.summary {
  background: #FFFFFF;
  border: 1px solid #F2F2F3;
  border-radius: 16px;
  padding: 24px;

  @include media('mobile') {
    padding: 16px;
  }

  &__text {
    margin-bottom: 24px;

    @include media('mobile') {
      font-size: 19px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .t-headline-3 {
      @include media('mobile') {
        margin-bottom: 0;
      }
    }
  }

  &__price {
    @include media('mobile') {
      letter-spacing: 0.0025em;
    }
  }

  &__inner {
    margin-bottom: 24px;

    @include media('mobile') {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.summary-item {
  &__title {
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  &__block {
    border: 1px solid #E5E5E7;
    border-radius: 8px;
  }

  &__row {
    display: flex;
    border-bottom: 1px solid #E5E5E7;
    padding: 16px;

    &:first-of-type {
      color: #81828C;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__block-item {

    &_monitor {
      flex: .9;
    }

    &_type {
      flex: 0.5;

      @include media('mobile') {
        display: none;
      }
    }

    &_month {
      flex: none;
      min-width: 76px;
      text-align: right;
    }
  }
}