.change-password {
  width: 368px;

  @include media('mobile') {
    width: 100%;
  }

  .t-headline-2 {
    text-align: center;

    @include media('mobile') {
      text-align: left;
    }
  }
  .t-headline-3 {
    margin-top: 64px;
  }
  .field {
    margin-top: 48px;
  }
  .button {
    margin-top: 48px;
    width: 100%;
  }
}
