.main-content-welcome {
  background: linear-gradient(180deg, #F8F8F8 0%, $main1 100%);

  &__inner {
    padding-top: 100px;
    display: flex;

    @include media('tablet') {
      display: block;
    }

    @include media('mobile') {
      padding-top: 64px;
    }
  }

  &__col {
    position: relative;
    width: 50%;

    @include media('tablet') {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      margin: 0 auto;

      @include media('tablet') {
        width: 80%;
      }

      @include media('mobile') {
        width: auto;
      }
    }

    @include media('mobile') {
      &:last-of-type {
        margin-top: 64px;
      }
    }
  }

  &__right {
    position: absolute;
    bottom: 0;
    right: -28%;
  }

  &__left {
    position: absolute;
    bottom: 0;
    left: -29%;
  }

  &__item {
    margin-bottom: 32px;
    max-width: 468px;

    @include media('tablet') {
      max-width: max-content;
      margin: 0 auto 32px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {

    @include media('mobile') {
      text-align: center;
    }

    span {
      color: $indigo;
    }
  }

  &__desc {
    color: $main8;

    @include media('mobile') {
      text-align: center;

      br {
        display: none;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;

    @include media('mobile') {
      display: block;
    }
  }

  &__button {
    padding: 16px 52.5px;

    @include media('mobile') {
      margin: auto;
    }
  }

  &__button-text {
    margin-left: 16px;

    @include media('mobile') {
      margin: 16px auto 0;
    }
  }
}
