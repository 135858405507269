.simple-monitoring {
  width: 564px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media('mobile') {
    width: 100%;
    margin-bottom: 110px;
  }

  .sequence {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -23px auto 76px;

    @include media('mobile') {
     margin: 0 0 32px;
    }

    div {
      color: $main5;
      font-size: 14px;
      display: flex;
      align-items: center;

      @include media('mobile') {
        display: block;
        width: 50%;
        text-align: center;
      }

      p {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $main5;
        font-weight: 600;
        margin-right: 10px;
        border-radius: 50%;
        font-size: 11px;
        flex-shrink: 0;
        transform: translateY(1px);

        @include media('mobile') {
          margin: 0 auto 10px;
        }
      }

      &.active {
        color: $main18;
        font-weight: 600;

        p {
          border: 2px solid $main18;
          color: $white;
          background-color: $main18;
        }
      }

      &:not(:last-child) {
        &::after {
          content: '';
          display: block;
          width: 64px;
          height: 1px;
          background-color: $main2;
          margin: 0 16px;

          @include media('mobile') {
            display: none;
          }
        }
      }
    }
  }

  .add-monitoring {
    display: flex;
    justify-content: flex-end;
    margin-top: 64px;

    @include media('mobile') {
      justify-content: flex-start;
      margin-top: 48px;
    }

    .button {

      @include media('mobile') {
        width: 100%;
      }
    }
  }
}