.checkbox {
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;

  &__btn {
    margin-right: 8px;
  }

  &__icon {
    border: 1px solid #E5E5E7;
    width: 20px;
    height: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      background: #2A2B3A;
      opacity: 0;
      transition: .3s ease;
    }
  }

  &__error {
    color: $red;
    position: absolute;
    bottom: -15px;
  }

  &_active {
    .checkbox__icon {

      &:after {
        opacity: 1;
      }
    }
  }
}