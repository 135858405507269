.button {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;
  position: relative;
  transition: all 0.3s ease;

  @include media('mobile') {
    margin: 8px 0;
  }

  &.loading {
    pointer-events: none;

    & > *:not([class="button__loader"]) {
      visibility: hidden;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &_size {
    &-big {
      height: 48px;
      padding: 0 24px;
    }

    &-medium {
      height: 40px;
      padding: 0 20px;
    }

    &-small {
      height: 32px;
      padding: 0 16px;
    }
  }

  &_bc {
    &-main18 {
      background-color: $main18;
      color: $white;

      &:hover {
        background-color: $main16;
      }

      &.disabled {
        background-color: $main4;
      }

      svg {
        fill: $white;
      }
    }

    &-indigo {
      background-color: $indigo;
      color: $white;

      &:hover {
        background-color: #2548D6;
      }

      &.disabled {
        background-color: #ABB8ED;
      }

      svg {
        fill: $white;
      }
    }

    &-red {
      background-color: $red;
      color: $white;

      &:hover {
        background-color: $red;
      }

      &.disabled {
        background-color: $main4;
      }

      svg {
        fill: $white;
      }
    }

    &-main2 {
      background-color: $main2;

      &:hover {
        background-color: $main4;
      }

      &.disabled {
        color: $main6;

        svg {
          fill: $main6;
        }
      }
    }

    &-white {
      background-color: $white;

      &:hover {
        background-color: $white;
      }

      &.disabled {
        color: $main6;

        svg {
          fill: $main6;
        }
      }
    }

    &-transparent {
      background-color: transparent;

      &:hover {
        background-color: $main2;
      }

      &.disabled {
        color: $main6;

        svg {
          fill: $main6;
        }
      }
    }
  }

  .icon-left, .icon-right, .icon-center {
    width: 24px;
    height: 24px;
  }

  .icon-left {
    margin: 0 9.5px 0 -2.5px;
  }

  .icon-right {
    margin: 0 -2.5px 0 9.5px;
  }

  .icon-center {
    margin: 0 -11px;
  }

  &__loader {
    width: 100%;
    height: 100%;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;

    svg {
      width: 20px;
      height: 20px;
      animation: 1s linear infinite rotate;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}