.page-text {
  width: 650px;

  @include media('mobile') {
    width: 100%;
    //overflow-y: scroll;
    //height: 100vh;
    //padding-bottom: 100px;
  }

  &__title {
    text-align: center;
    margin-bottom: 44px;

    @include media('mobile') {
      text-align: left;
    }
  }

  &__block {

    ul {
      list-style: disc;
      margin-left: 17px;

      li {
        &::marker {
          color: #2F54EB;
        }
      }
    }
  }
}