.loader {
  width: 100%;
  height: 64px;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  .spinner {
    height: 64px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .spinner--bar {
      width: 8px;
      height: 50%;
      background: $main18;
      &:nth-child(1) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 50ms;
        animation-delay: 50ms;
      }
      &:nth-child(2) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 100ms;
        animation-delay: 100ms;
      }
      &:nth-child(3) {
        -webkit-animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation: jump 660ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
        -webkit-animation-delay: 150ms;
        animation-delay: 150ms;
      }
    }
  }
}

@-webkit-keyframes jump {
  0%, 100% {
    transform: scaleY(1);
  }
  35% {
    transform: scaleY(1.6);
  }
  70% {
    transform: scaleY(0.8);
  }
}

@keyframes jump {
  0%, 100% {
    transform: scaleY(1);
  }
  35% {
    transform: scaleY(1.6);
  }
  70% {
    transform: scaleY(0.8);
  }
}