.popup {
  width: 100%;
  height: 100vh;
  background: rgba(42, 43, 58, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  .popup-inner {
    width: 500px;
    height: auto;
    padding: 64px;
    background: $white;
    border-radius: 16px;
    position: relative;
    .buttons {
      display: flex;
      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
    .popup__close {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 36px;
    display: flex;
  }
}