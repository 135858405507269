.profile-billing {
  .summary {
    margin-bottom: 16px;

    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .calendar {
        display: flex;
        align-items: center;
        position: relative;

        &-month {
          margin-right: 8px;
          color: $indigo;
          margin-bottom: 0;
        }

        &-btn {
          display: flex;
        }

        .ant-picker {
          position: absolute;
          cursor: pointer;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;

          &-input {
            > input {
              cursor: pointer;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
  .payment-methods {
    .method-block {
      width: 100%;
      padding: 16px;
      background-color: transparent;
      border: 1px solid #E5E5E7;
      border-radius: 8px;
      &:not(:first-child) {
        margin-top: 16px;
      }
      .top-line {
        display: flex;
        justify-content: space-between;
        .name {
          font-size: 18px;
          line-height: 20px;
          font-weight: 500;
        }
        .icon {
          width: 28px;
          height: 20px;
        }
      }
      .card-number {
        color: $main5;
        margin: 8px 0;
      }
      .payment-method-status {
        font-weight: bold;
      }
      .payment-method-active {
        color: $green
      }
      .payment-method-inactive {
        color: $red
      }
      .bottom-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .use-as-default {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: $indigo;
          cursor: pointer;
        }
        .default {
          display: flex;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $main5;
          flex-grow: 1;
          text-align: center;
        }
        .remove {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: $red;
            margin-left: 4px;
          }
        }
      }
    }
    .button {
      margin-top: 24px;
      margin-bottom: 0;

      @include media('mobile') {
        margin-top: 16px;
      }
    }
    .checkbox {
      margin-top: 24px;

      @include media('mobile') {
        margin-top: 16px;
      }
    }
  }
  .transactions {
    padding-top: 24px;
    margin-top: 20px;
    .container {
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .label {
        padding: 4px;
        background-color: $red;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        border-radius: 2px;
      }
      .transaction-block {
        width: 100%;
        padding: 16px;
        background-color: $main1;
        &:not(:last-child) {
          border-bottom: 1px solid $main2;
        }
        .top-line {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          .title {
            flex-grow: 1;
          }
          .failed {
            margin-left: 8px;
          }
          .amount {
            flex-shrink: 0;
            margin-left: 8px;
          }
        }
        .bottom-line {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          .date {
            color: $main5;
          }
          .icon {
            fill: $main4;
            transform: rotate(180deg);
            transition: all 0.3s ease;
          }
        }
      }
      .transaction-block_invalid {
        width: 100%;
        padding: 16px;
        background-color: $main2;
        .bottom-line {
          margin-top: 8px;
          display: flex;
          .icon {
            width: 28px;
            height: 20px;
            margin-right: 8px;
          }
          .card-number {
            color: $main5;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .t-headline-4 {
    margin-bottom: 24px;

    @include media('mobile') {
      margin-bottom: 16px;
    }
  }
}
